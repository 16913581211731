import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';

import { map, Observable } from 'rxjs';

import { OpenServiceRequestStatuses, ServiceRequestPost, ServiceRequestResponse } from 'src/app/modules/core/models/svc.model';

import * as fromRoot from 'src/app/modules/ngrx-store/reducers';
import * as ServiceRequestActions from 'src/app/modules/ngrx-store/service-requests/service-requests.actions';
import * as ServiceRequestsSelectors from 'src/app/modules/ngrx-store/service-requests/service-requests.selectors';

@Injectable()
export class ServiceRequestsFacade {
	serviceRequests$ = this.store.pipe(select(ServiceRequestsSelectors.getServiceRequests));
	serviceRequestsPending$ = this.store.pipe(select(ServiceRequestsSelectors.getServiceRequestsPending));
	postServiceRequestPending$ = this.store.pipe(select(ServiceRequestsSelectors.getPostServiceRequestPending));
	postServiceRequestFailure$ = this.store.pipe(select(ServiceRequestsSelectors.getPostServiceRequestFailure));
	postServiceRequestSuccess$ = this.store.pipe(select(ServiceRequestsSelectors.getPostServiceRequestSuccess));

	constructor(protected store: Store<fromRoot.AppDomainPartialState>) { }

	getOpenServiceRequests(): Observable<ServiceRequestResponse[] | undefined> {
		return this.serviceRequests$.pipe(
			map(sr => sr?.filter(sr => {
				if (sr.serviceRequestId) {
					// SR
					return (sr.status && (OpenServiceRequestStatuses.includes(sr.status)) || sr.serviceRequestLineItems?.find(srli => srli.status && OpenServiceRequestStatuses.includes(srli.status)));
				} else {
					// Queue only
					return sr.serviceRequestQueueItems?.find(srqi => srqi.status && OpenServiceRequestStatuses.includes(srqi.status));
				}
			}))
		);
	}

	getClosedServiceRequests(): Observable<ServiceRequestResponse[] | undefined> {
		return this.serviceRequests$.pipe(
			map(sr => sr?.filter(sr => {
				if (sr.serviceRequestId) {
					// SR
					return !((sr.status && (OpenServiceRequestStatuses.includes(sr.status)) || sr.serviceRequestLineItems?.find(srli => srli.status && OpenServiceRequestStatuses.includes(srli.status))));
				} else {
					// Queue only
					return !(sr.serviceRequestQueueItems?.find(srqi => srqi.status && OpenServiceRequestStatuses.includes(srqi.status)));
				}
			}))
		);
	}

	loadServiceRequests(): void {
		this.store.dispatch(ServiceRequestActions.loadServiceRequests());
	}

	postServiceRequest(serviceRequest: ServiceRequestPost): void {
		this.store.dispatch(ServiceRequestActions.postServiceRequest({ serviceRequest }));
	}
}