import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Router, NavigationEnd } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { MatDialogRef } from '@angular/material/dialog';

import { combineLatest, filter, take } from 'rxjs';

import { ApplicationInsightsService } from 'src/app/modules/core/services/telemetry.service';
import { BrandService } from 'src/app/modules/core/services/brand.service';
import { DialogService } from 'src/app/modules/core/services/dialog.service';
import { ModalComponent } from 'src/app/modules/core/components/modal/modal.component';
import { SettingsService } from 'src/app/modules/core/services/settings.service';
import { TermsOfUseModalComponent } from 'src/app/modules/core/components/terms-of-use-modal/terms-of-use-modal.component';

import { CORE_ROUTES } from 'src/app/modules/shared/constants/route-paths';
import { TERMS_ACKNOWLEDGED } from 'src/app/modules/shared/constants/local-storage';
import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';

import { AppFacade } from 'src/app/modules/ngrx-store/app/app.facade';
import { ConstructionProgressFacade } from 'src/app//modules/ngrx-store/construction-progress/construction-progress.facade';
import { ServiceRequestsFacade } from 'src/app//modules/ngrx-store/service-requests/service-requests.facade';
import { YourHomeFacade } from 'src/app/modules/ngrx-store/your-home/your-home.facade';

@Component({
	selector: 'cp-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent extends UnsubscribeOnDestroy implements OnInit {
	private appInsights: ApplicationInsightsService;

	private brandName: string;

	private termsOfUseModal?: MatDialogRef<unknown>;

	private logoutModal?: MatDialogRef<unknown>;

	myAccountUrl: string | undefined;

	isPreview?: boolean;
	
	constructor(
		private brandService: BrandService,
		private dialogService: DialogService,
		private domSanitizer: DomSanitizer,
		private matIconRegistry: MatIconRegistry,
		private router: Router,
		private settingsService: SettingsService,
		private appFacade: AppFacade,
		private constructionProgressFacade: ConstructionProgressFacade,
		private serviceRequestsFacade: ServiceRequestsFacade,
		private yourHomeFacade: YourHomeFacade,
		private idle: Idle
	) {
		super();

		this.appInsights = new ApplicationInsightsService(this.router);
		this.brandName = this.brandService.getBrand().name;
	}

	ngOnInit(): void {
		// Combine router events with settings observable
		combineLatest([
			this.router.events.pipe(
				this.takeUntilDestroyed(),
				filter(event => event instanceof NavigationEnd)
			),
			this.settingsService.getSettings()
		]).pipe(
			this.takeUntilDestroyed()
		).subscribe(([routerEvent, settings]) => {
			if (routerEvent instanceof NavigationEnd) {
				const brand = this.brandService.getBrand();

				this.isPreview = routerEvent.url.includes(CORE_ROUTES.Preview);

				if (!this.isPreview) {
					// Set myAccountUrl for non-preview route
					this.myAccountUrl = settings.brandConfigs[brand.name].logoutUrl;

					// Set up idle check for non-preview route
					this.setupIdleCheck();
				}
			}
		});

		combineLatest([this.settingsService.getSettings(),
			this.appFacade.userInfo$,
			this.yourHomeFacade.homeDetails$
		]).pipe(
			this.takeUntilDestroyed()
		).subscribe(
			([settings, userInfo, homeDetails]) => {
				if (settings && userInfo && homeDetails?.salesAgreementNumber) {
					// If user info has loaded see if terms of use need to be displayed
					if (!this.termsOfUseModal) {
						// Check if terms of use have been acknowledged in current browser session
						const termsOfUseAcknowledged = Boolean(localStorage.getItem(TERMS_ACKNOWLEDGED));
	
						// If terms of use have not been acknowledged show modal
						if (!termsOfUseAcknowledged) {
							this.termsOfUseModal = this.dialogService.openDialog(TermsOfUseModalComponent, { disableClose: true });
						}
					}

					this.appInsights.initializeTelemetry(this.brandName, homeDetails.salesAgreementNumber, settings, userInfo);
				}
			}
		);

		// On app init check if homeDetails have been loaded successfully, if so check for new
		// construction updates and service requests
		this.yourHomeFacade.homeDetailsSuccess$.pipe(
			filter(success => !!success),
			take(1)
		).subscribe(() => {
			this.constructionProgressFacade.loadConstructionUpdates();
			this.serviceRequestsFacade.loadServiceRequests();
		});

		this.brandService.applyBrandTheme();
		this.registerCustomSVGIcons();
	}

	setupIdleCheck() {
		// Set idle time to 14 minutes. After this time of inactivity, the warning dialog will be shown.
		this.idle.setIdle(14 * 60);

		// Set timeout to 1 minute. 1 minute after the idle time, if the user still hasn't interacted, log them out.
		this.idle.setTimeout(60);
	
		this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
	
		this.idle.onTimeout.pipe(
			this.takeUntilDestroyed()
		).subscribe(() => {
			// Logout the user when the total timeout is reached
			this.logoutUser();
		});
	
		this.idle.onTimeoutWarning.pipe(
			this.takeUntilDestroyed()
		).subscribe(() => {
			// Show warning modal here, only if not already shown
			if (!this.logoutModal) {
				this.openLogoutDialog();
			}
		});

		this.idle.watch();
	}

	openLogoutDialog() {
		// Ensure dialog is only opened once
		if (!this.logoutModal) {
			this.logoutModal = this.dialogService.openDialog(ModalComponent, {
				data: {
					title: 'Are you still there?',
					content: 'You have been inactive for a while. For your security, you will be signed out in 1 minute.',
					primaryLabel: 'Log Out',
					primaryFunction: () => this.logoutUser(),
					secondaryLabel: 'Continue Session',
					secondaryFunction: () => {
						// Reset dialogRef to allow dialog to be shown again
						this.logoutModal = undefined;

						// Reset idle watch and allow dialog to be shown again
						this.idle.watch();
					}
				}
			});

			this.logoutModal.afterClosed().pipe(
				this.takeUntilDestroyed()
			).subscribe(() => {
				// Reset dialogRef to allow dialog to be shown again
				this.logoutModal = undefined;

				// Reset idle watch and allow dialog to be shown again
				this.idle.watch();
			});
		}
	}

	logoutUser() {
		// Take user to myAccount login page for non-preview route
		if (!this.isPreview && this.myAccountUrl) {
			window.location.href = this.myAccountUrl;
		}
	}

	// register custom svg icons for use in <mat-icon> components
	registerCustomSVGIcons(): void {
		/*
		 * Options Selections
		 */
		this.matIconRegistry
			.addSvgIcon('options_exterior', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/options-selections/exterior.svg'));
		
		this.matIconRegistry
			.addSvgIcon('options_rooms', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/options-selections/rooms.svg'));
		
		this.matIconRegistry
			.addSvgIcon('options_flooring', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/options-selections/flooring.svg'));
		
		this.matIconRegistry
			.addSvgIcon('options_finishes', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/options-selections/finishes.svg'));
		
		this.matIconRegistry
			.addSvgIcon('options_extras', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/options-selections/extras.svg'));

		/*
		 * Navigation Items
		 */
		this.matIconRegistry
			.addSvgIcon('nav_your_home', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/navigation/your-home.svg'));
		this.matIconRegistry
			.addSvgIcon('nav_financial_services', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/navigation/financial-services.svg'));

		/*
		 * SCAR Progress
		 */
		this.matIconRegistry
			.addSvgIcon('scar_introduction', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/scar/introduction.svg'));
		
		this.matIconRegistry
			.addSvgIcon('scar_foundation', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/scar/foundation.svg'));
		
		this.matIconRegistry
			.addSvgIcon('scar_drywall', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/scar/drywall.svg'));
		
		this.matIconRegistry
			.addSvgIcon('scar_complete', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/scar/complete.svg'));
		
		/*
		 * Footer
		 */
		this.matIconRegistry
			.addSvgIcon('footer_allyant', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/footer/allyant.svg'));
		
		this.matIconRegistry
			.addSvgIcon('footer_equal_housing', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/footer/equal-housing.svg'));
		
		/*
		 * Service Requests
		 */
		this.matIconRegistry
			.addSvgIcon('service_requests_photo', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/service-requests/photo.svg'));
		
		this.matIconRegistry
			.addSvgIcon('upload_image', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/service-requests/upload-image.svg'));
	}
}