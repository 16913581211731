import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as ServiceRequestActions from 'src/app/modules/ngrx-store/service-requests/service-requests.actions';
import * as YourHomeActions from 'src/app/modules/ngrx-store/your-home/your-home.actions';
import { getLotId } from 'src/app/modules/ngrx-store/your-home/your-home.selectors';

import { SvcService } from 'src/app/modules/core/services/svc.service';

import { displayError } from 'src/app/modules/shared/utilities/notifications.utils';

@Injectable()
export class ServiceRequestsEffects {
	constructor(
		private actions$: Actions,
		private svcService: SvcService,
		private store: Store
	) { }

	// Retrieves Service Request for Service Request page.
	loadServiceRequests$ = createEffect(() => this.actions$.pipe(
		ofType(YourHomeActions.loadHomeDetailsSuccess, ServiceRequestActions.loadServiceRequests),
		withLatestFrom(this.store.select(getLotId)),
		map(([, lotId]) => lotId),
		filter(Boolean), // Checks to make sure lotId is not undefined
		switchMap(lotId => 
			this.svcService.getServiceRequests(lotId).pipe(
				map((serviceRequests) => ServiceRequestActions.loadServiceRequestsSuccess({ serviceRequests })),
				catchError((err) => of(ServiceRequestActions.loadServiceRequestsFailure({ error: err })))
			))
	));

	loadServiceRequestsFailure$ = createEffect(() => this.actions$.pipe(
		ofType(ServiceRequestActions.loadServiceRequestsFailure),
		tap(err => {
			displayError(err.error);
		})
	),
	{ dispatch: false }
	);

	postServiceRequest$ = createEffect(() => this.actions$.pipe(
		ofType(ServiceRequestActions.postServiceRequest),
		switchMap(({ serviceRequest }) => this.svcService.postServiceRequest(serviceRequest).pipe(
			map((serviceRequest) => ServiceRequestActions.postServiceRequestSuccess({ serviceRequest })),
			catchError((err) => of(
				ServiceRequestActions.postServiceRequestFailure({
					error: err
				})
			))
		))
	));

	postServiceRequestFailure$ = createEffect(() => this.actions$.pipe(
		ofType(ServiceRequestActions.postServiceRequestFailure),
		tap(err => {
			displayError(err.error);
		})
	),
	{ dispatch: false }
	);
}